import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import {
  ApplicationConfig,
  EnvironmentInjector,
  inject,
  runInInjectionContext,
} from '@angular/core';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './lib/auth.service';

const uri = '/graphql';

export function apolloOptionsFactory(): ApolloClientOptions<unknown> {
  const httpLink = inject(HttpLink);
  const injector = inject(EnvironmentInjector);

  const errorLink = onError(error => {
    if (error.networkError) {
      runInInjectionContext(injector, () => {
        const authService = inject(AuthService);
        const httperror = error.networkError as HttpErrorResponse;

        if (httperror.error.errors[0]?.extensions?.code === 'UNAUTHENTICATED') {
          authService.logout();
        }
      });
    }
  });

  return {
    link: ApolloLink.from([errorLink, httpLink.create({ uri })]),
    cache: new InMemoryCache(),
    queryDeduplication: true,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  };
}

export const graphqlProvider: ApplicationConfig['providers'] = [
  Apollo,
  {
    provide: APOLLO_OPTIONS,
    useFactory: apolloOptionsFactory,
  },
];
