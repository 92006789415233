import { Routes } from '@angular/router';
import { isLoggedInGuard } from './lib/is-logged-in.guard';

export const routes: Routes = [
  {
    path: 'auth',
    loadComponent: () =>
      import('./routes/auth/auth-layout.component').then(
        m => m.AuthLayoutComponent
      ),
    children: [
      {
        path: 'login',
        loadComponent: () =>
          import('./routes/auth/login/login.component').then(
            m => m.LoginComponent
          ),
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login',
      },
    ],
  },
  {
    path: '',
    canActivate: [isLoggedInGuard],
    loadComponent: () =>
      import('./routes/layout.component').then(m => m.LayoutComponent),
    children: [
      {
        path: 'applicants',
        loadComponent: () =>
          import('./routes/applicants/applicants.component').then(
            m => m.ApplicantsComponent
          ),
      },
      {
        path: 'applicants/:id',
        loadComponent: () =>
          import('./routes/applicants/applicant/applicant.component').then(
            m => m.ApplicantComponent
          ),
      },
      {
        path: 'archive',
        loadComponent: () =>
          import('./routes/archive/archive.component').then(
            m => m.ArchiveComponent
          ),
      },
      {
        path: 'statistics',
        loadComponent: () =>
          import('./routes/statistics/statistics.component').then(
            m => m.StatisticsComponent
          ),
      },
      {
        path: 'templates',
        loadComponent: () =>
          import('./routes/templates/templates.component').then(
            m => m.TemplatesComponent
          ),
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'applicants',
      },
    ],
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'auth',
  },
];
