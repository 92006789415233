import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideHttpClient } from '@angular/common/http';
import { graphqlProvider } from './graphql.provider';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { JobCategoryPipe } from './lib/pipes/job-category.pipe';
import { ApplicanStatePipe } from './lib/pipes/applican-state.pipe';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(),
    graphqlProvider,
    provideAnimationsAsync('noop'),
    JobCategoryPipe,
    ApplicanStatePipe,
  ],
};
